import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import { ProtectedRoute } from './auth/ProtectedRoute';
import Login from './pages/Login';
import { useAuth } from './auth/AuthContext';
import './index.css';

// Dashboard component (protected)
const Dashboard = () => {
  const [healthStatus, setHealthStatus] = useState('Checking...');
  const [error, setError] = useState('');
  const { user, logout } = useAuth();

  const apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3001';

  useEffect(() => {
    fetch(`${apiUrl}/health`)
      .then((response) => response.json())
      .then((data) => {
        setHealthStatus(`${data.status}: ${data.message}`);
      })
      .catch((err) => {
        setError('Error connecting to the backend');
        console.error(err);
      });
  }, [apiUrl]);

  return (
    <div className="dashboard">
      <header>
        <h1>Personal Hub</h1>
        <div className="user-info">
          {user?.email && <span>Welcome, {user.email}</span>}
          <button onClick={logout} className="logout-button">
            Logout
          </button>
        </div>
      </header>

      <div className="card">
        <h2>Backend Status</h2>
        {error ? <p className="error">{error}</p> : <p>{healthStatus}</p>}
        <p className="info">Using API at: {apiUrl}</p>
      </div>
    </div>
  );
};

// App component with routing
const AppWithAuth = () => {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
};

// Routes component
const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <div>
              <h1>Page Not Found</h1>
              <Link to="/">Go Home</Link>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppWithAuth;

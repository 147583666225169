import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js';

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: any | null;
  login: (username: string, password: string) => Promise<void>;
  loginWithGoogle: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Get configuration from environment variables
const USER_POOL_ID = import.meta.env.VITE_USER_POOL_ID;
const CLIENT_ID = import.meta.env.VITE_USER_POOL_CLIENT_ID;
const COGNITO_DOMAIN = import.meta.env.VITE_COGNITO_DOMAIN;
// Add trailing slash to ensure it matches the callback URLs in Cognito
const REDIRECT_URI = window.location.origin + '/';

// Configure Cognito User Pool
const userPool = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: CLIENT_ID,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<any | null>(null);

  // Check if user is already authenticated
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
          // Get user session
          currentUser.getSession((err: Error | null, session: any) => {
            if (err) {
              console.error('Error getting session:', err);
              setIsAuthenticated(false);
              setUser(null);
              setIsLoading(false);
              return;
            }

            if (session.isValid()) {
              setIsAuthenticated(true);
              // Get user attributes
              currentUser.getUserAttributes((err, attributes) => {
                if (err) {
                  console.error('Error getting user attributes:', err);
                  setIsLoading(false);
                  return;
                }

                const userAttributes = attributes?.reduce((acc: any, attr) => {
                  acc[attr.getName()] = attr.getValue();
                  return acc;
                }, {});

                setUser(userAttributes);
                setIsLoading(false);
              });
            } else {
              setIsAuthenticated(false);
              setUser(null);
              setIsLoading(false);
            }
          });
        } else {
          setIsAuthenticated(false);
          setUser(null);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Auth check error:', error);
        setIsAuthenticated(false);
        setUser(null);
        setIsLoading(false);
      }
    };

    checkAuth();

    // Check for Cognito tokens or authorization code in URL (after redirect from Google login)
    const urlParams = new URLSearchParams(window.location.search);
    const idToken = urlParams.get('id_token');
    const accessToken = urlParams.get('access_token');
    const code = urlParams.get('code');
    const error = urlParams.get('error');
    const errorDescription = urlParams.get('error_description');

    console.log('URL params:', { idToken, accessToken, code, error, errorDescription });

    if (idToken && accessToken) {
      // Implicit flow response
      console.log('Implicit flow authentication successful');
      setIsAuthenticated(true);
      // Remove tokens from URL to prevent security issues
      window.history.replaceState({}, document.title, window.location.pathname);
      setIsLoading(false);
    } else if (code) {
      // Authorization code flow response
      console.log('Authorization code received:', code);
      // In a real implementation, you would exchange this code for tokens
      // For now, we'll just set the user as authenticated
      setIsAuthenticated(true);
      window.history.replaceState({}, document.title, window.location.pathname);
      setIsLoading(false);
    } else if (error) {
      // Authentication error
      console.error('Authentication error:', error, errorDescription);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  // Login with username and password (Cognito)
  const login = (username: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          setIsAuthenticated(true);
          setUser({
            username,
            // You can add more user details here
          });
          resolve();
        },
        onFailure: (err) => {
          console.error('Login error:', err);
          reject(err);
        },
      });
    });
  };

  // Login with Google
  const loginWithGoogle = () => {
    // Try using the code grant flow instead of implicit flow
    const googleLoginUrl = `${COGNITO_DOMAIN}/oauth2/authorize?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${encodeURIComponent(
      REDIRECT_URI
    )}&identity_provider=Google`;

    console.log('Redirecting to:', googleLoginUrl);

    // Add a small delay to ensure console log is visible
    setTimeout(() => {
      window.location.href = googleLoginUrl;
    }, 100);
  };

  // Logout
  const logout = () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
      setIsAuthenticated(false);
      setUser(null);

      // Redirect to Cognito logout URL to completely sign out
      const logoutUrl = `${COGNITO_DOMAIN}/logout?client_id=${CLIENT_ID}&logout_uri=${encodeURIComponent(
        REDIRECT_URI
      )}`;

      window.location.href = logoutUrl;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        login,
        loginWithGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

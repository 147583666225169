import { useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import { Navigate } from 'react-router-dom';

export const Login = () => {
  const { isAuthenticated, isLoading, loginWithGoogle } = useAuth();
  const [error, setError] = useState<string | null>(null);

  // If already authenticated, redirect to home
  if (isAuthenticated && !isLoading) {
    return <Navigate to="/" replace />;
  }

  // Show loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <h1>Personal Hub</h1>
        <p>Sign in to access your personal dashboard</p>

        {error && <div className="error-message">{error}</div>}

        <button
          className="google-login-button"
          onClick={() => {
            try {
              loginWithGoogle();
            } catch (err) {
              setError('Failed to initiate Google login');
              console.error(err);
            }
          }}
        >
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default Login;
